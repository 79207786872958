import * as React from 'react'

import * as styles from '@/components/Community/Purpose/Purpose.module.scss'

const CommunityPurpose = () => {
  return (
    <section className={styles['purpose']}>
      <h3 className={styles['title']}>たとえば、こんな使い方</h3>
      <div className={styles['masonry']}>
        <div>同窓会</div>
        <div>ボードゲーム</div>
        <div>誕生日会</div>
        <div>女子会</div>
        <div>父の日</div>
        <div>撮影</div>
        <div>1Dayショップ</div>
        <div>マルシェ</div>
        <div>セミナー</div>
        <div>フリマ</div>
        <div>手作り教室</div>
        <div>個展</div>
        <div>会議</div>
        <div>研修</div>
        <div>打ち上げ</div>
        <div>送別会</div>
        <div>勉強会</div>
        <div>コラボイベント</div>
      </div>
      <a className={styles['button']} href='mailto:latempo2022@gmail.com'>
        レンタルの問合せ　→
      </a>
    </section>
  )
}

export default CommunityPurpose
