// extracted by mini-css-extract-plugin
export var description = "Facility-module--description--48TlU";
export var facility = "Facility-module--facility--mDKqq";
export var fadeDown = "Facility-module--fadeDown--avxpY";
export var fadeUp = "Facility-module--fadeUp--tcMCy";
export var flowing = "Facility-module--flowing--q9t4K";
export var image = "Facility-module--image--OkFYV";
export var info = "Facility-module--info--CoYnW";
export var lineIn = "Facility-module--lineIn--6Vj5S";
export var other = "Facility-module--other--JeXdX";
export var title = "Facility-module--title--JqY6l";