import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Community/Main/Main.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'

const CommunityMain = () => {
  return (
    <section className={styles['main']}>
      <div className={styles['imageWrap']}>
        <StaticImage
          className={styles['image']}
          src='../../../images/community/concept.png'
          alt='Concept'
          placeholder='blurred'
        />
      </div>
      <div className={styles['container']}>
        <h2 className={styles['title']}>
          その集まり、ぜひ
          <br />
          コミュニティスペースで。
        </h2>
        <p className={styles['description']}>
          鯖江が体験に溢れた場になるようにと願い、1階の飲食可能なカフェ空間をそのままコミュニティスペースとして貸出しています。
          <br />
          <MediaBreak media='mobile' />
          温かみのあるシンプルな空間で、ビジネスからプライベートまでさまざまなシーンにてご利用可能です。
          <br />
          <MediaBreak media='mobile' />
          La
          Tempoは、新しいコト・モノ・出会いに前向きなこのまちと共にあります。その後も続く関係性もどうか大切に、その時、その場で、皆さんの夢を膨らませ、しあわせを感じる一時を演出してみてください。
        </p>
      </div>
    </section>
  )
}

export default CommunityMain
