import * as React from 'react'

import * as styles from '@/components/Community/Ability/Ability.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'

const CommunityAbility = () => {
  return (
    <section className={styles['ability']}>
      <h3 className={styles['title']}>
        コミュニティスペースで
        <MediaBreak media='mobile' />
        できること｡
      </h3>
      <div className={styles['container']}>
        <div className={styles['item']}>
          <span className={styles['num']}>01</span>
          <div className={styles['head']}>
            いつでも
            <MediaBreak media='desktop' />
            気軽に
            <MediaBreak media='desktop' />
            レンタル｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            営業時間は11:00〜22:00。予約は電話またはメールにて受け付けています。誰でも気軽にレンタルすることが可能です（面談あり）。料金は、1時間の利用ごとに発生するシステム。現在のオプションは1階カフェスペースとキッチンの貸し切りとなっております。
          </div>
        </div>
        <div className={styles['item']}>
          <span className={styles['num']}>02</span>
          <div className={styles['head']}>
            目的に応じて
            <br />
            椅子や机を
            <MediaBreak media='desktop' />
            配置換え｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            レンタル当日は、目的に応じて椅子や机を自由に動かすことができます。装飾も取り外し可能な範囲であれば問題ありません。Wi-Fiやコンセント、壁面ホワイトボードも利用可能です。詳細は以下に記載の『Facility』『Information』の項目にてご確認ください。
          </div>
        </div>
        <div className={styles['item']}>
          <span className={styles['num']}>03</span>
          <div className={styles['head']}>
            イベントで
            <MediaBreak media='desktop' />
            得た収益は
            <MediaBreak media='desktop' />
            そのまま｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            La
            Tempoのコミュニティスペースは、プライベート利用だけでなくビジネス利用も可能です。その際も変わらず料金はレンタル代のみ。イベント開催や1Dayショップ等によって発生した収益を手数料等でこちらが間引くことはありません。
          </div>
        </div>
      </div>
    </section>
  )
}

export default CommunityAbility
