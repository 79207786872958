import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import CommunityAbility from '@/components/Community/Ability'
import CommunityEntry from '@/components/Community/Entry'
import CommunityFacility from '@/components/Community/Facility'
import CommunityInformation from '@/components/Community/Information'
import CommunityMain from '@/components/Community/Main'
import CommunityPurpose from '@/components/Community/Purpose'
import Layout from '@/components/Layout'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const KitchenPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true} dark={true}>
      <main>
        <PageTitle
          title='出会う、話す｡'
          page='community'
          curtain={false}
          crumbs={crumbs}
          crumbLabel='Community space'
        />
        <CommunityMain />
        <CommunityAbility />
        <CommunityPurpose />
        <CommunityFacility />
        <CommunityInformation />
        <CommunityEntry />
      </main>
    </Layout>
  )
}

export default KitchenPage

export const Head: HeadFC = ({ location }) => (
  <SEO title='出会う、話す｡' pathname={location.pathname} page='community' />
)
