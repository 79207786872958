import * as React from 'react'

import * as styles from '@/components/Community/Entry/Entry.module.scss'

const CommunityEntry = () => {
  return (
    <section className={styles['entry']}>
      <h3 className={styles['title']}>レンタル、募集中です｡</h3>
      <a className={styles['button']} href='mailto:latempo2022@gmail.com'>
        レンタルの問合せ　→
      </a>
    </section>
  )
}

export default CommunityEntry
