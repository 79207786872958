// extracted by mini-css-extract-plugin
export var caution = "Information-module--caution--Ydz9y";
export var description = "Information-module--description--NE9lv";
export var detail = "Information-module--detail--TvXTE";
export var fadeDown = "Information-module--fadeDown--MoO2X";
export var fadeUp = "Information-module--fadeUp--AbbMg";
export var flowing = "Information-module--flowing--Vkipk";
export var group = "Information-module--group--4g3-0";
export var head = "Information-module--head--tHT5K";
export var information = "Information-module--information--0NeWF";
export var label = "Information-module--label--MnBwQ";
export var lineIn = "Information-module--lineIn--doUaM";
export var title = "Information-module--title--WH1tO";