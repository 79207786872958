// extracted by mini-css-extract-plugin
export var ability = "Ability-module--ability--ZBy-w";
export var container = "Ability-module--container--6RHwa";
export var description = "Ability-module--description--7n+rP";
export var divider = "Ability-module--divider--KoHll";
export var fadeDown = "Ability-module--fadeDown---mmAg";
export var fadeUp = "Ability-module--fadeUp--f3Aej";
export var flowing = "Ability-module--flowing--juUEE";
export var head = "Ability-module--head--Gch6T";
export var item = "Ability-module--item--HYlS6";
export var lineIn = "Ability-module--lineIn--ckb2j";
export var num = "Ability-module--num--M859X";
export var title = "Ability-module--title--TUKb+";