// extracted by mini-css-extract-plugin
export var container = "Main-module--container--m9KlU";
export var description = "Main-module--description--g+mGm";
export var fadeDown = "Main-module--fadeDown--QLKyd";
export var fadeUp = "Main-module--fadeUp--iOUZr";
export var flowing = "Main-module--flowing--FsYft";
export var image = "Main-module--image--Z-m1l";
export var imageWrap = "Main-module--imageWrap--Z0k++";
export var lineIn = "Main-module--lineIn--q0snw";
export var main = "Main-module--main--oPmHp";
export var title = "Main-module--title--3-b87";