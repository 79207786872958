import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/navigation'
import '@/styles/swiper.scss'
import * as styles from '@/components/Community/Facility/Facility.module.scss'
import Parallelogram from '@/components/Path/Parallelogram'

const CommunityFacility = () => {
  return (
    <section className={styles['facility']}>
      <h3 className={styles['title']}>Facility</h3>
      <p className={styles['description']}>こちらはレンタル可能な設備です。</p>
      <Parallelogram />
      <Swiper
        className='slider-parallelogram'
        grabCursor={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
        loop={true}
        loopedSlides={4}
        navigation={true}
        slidesPerView={'auto'}
        centeredSlides={true}
        modules={[Autoplay, Navigation]}
      >
        <SwiperSlide>
          <StaticImage
            className={styles['image']}
            src='../../../images/community/facility1.png'
            alt='Community-1'
            placeholder='blurred'
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className={styles['image']}
            src='../../../images/community/facility2.png'
            alt='Community-2'
            placeholder='blurred'
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className={styles['image']}
            src='../../../images/community/facility3.png'
            alt='Community-3'
            placeholder='blurred'
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className={styles['image']}
            src='../../../images/community/facility4.png'
            alt='Community-4'
            placeholder='blurred'
          />
        </SwiperSlide>
      </Swiper>
      <div className={styles['info']}>
        <div className={styles['other']}>
          <span>無料の設備</span>
          <p>
            キッチン、Wi-Fi、モニター、コンセント、ホワイトボード、
            <br />
            マーカー、クリーナー、テーブル、椅子
          </p>
        </div>
        <div className={styles['other']}>
          <span>その他共用設備</span>
          <p>トイレ、駐車場</p>
        </div>
      </div>
    </section>
  )
}

export default CommunityFacility
