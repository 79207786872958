import * as React from 'react'

import * as styles from '@/components/Community/Information/Information.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'

const CommunityInformation = () => {
  return (
    <section className={styles['information']}>
      <h3 className={styles['title']}>Information</h3>
      <p className={styles['description']}>
        こちらはコミュニティスペースを
        <MediaBreak media='mobile' />
        ご利用する際に必要な情報です。
      </p>
      <div>
        <div className={styles['group']}>
          <span className={styles['label']}>料金</span>
          <div>
            <span className={styles['head']}>
              平日 2,200円/時間
              <br />
              土日祝 2,700円/時間
            </span>
            <p className={styles['detail']}>定員1〜20名</p>
          </div>
        </div>
        <div className={styles['group']}>
          <span className={styles['label']}>時間</span>
          <div>
            <span className={styles['head']}>11:00〜22:00</span>
            <p className={styles['detail']}>
              予約は1ヶ月前まで受け付けております。
            </p>
          </div>
        </div>
      </div>
      <p className={styles['caution']}>
        ※１
        <br />
        登録手続き等は不要。利用規約への同意と面談が必要です。少しでも興味がある方はぜひ見学にいらしてください。ご案内致します。また他にも気になることがあれば気軽にお問い合わせください。
        <br />
        <br />
        ※２
        <br />
        ご利用は事前予約制です。予約方法は、問合せと同じ電話番号またはメールアドレスにて受け付けております。ご連絡はレンタルしたい日の1ヶ月前までにお願いします。早期予約はいつでもどうぞ。
        <br />
        <br />
        ※３
        <br />
        料金は1時間毎のレンタル料のみです。お客様がコミュニティスペースを利用して得た収益をこちらが手数料等で間引くことはありません。ただし、商売の内容やビジネスモデルによってはこちらからお断りさせて頂くことがございます。予めご了承ください。
      </p>
    </section>
  )
}

export default CommunityInformation
